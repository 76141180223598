.dialog {
  position: fixed;
  z-index: 99;
  background-color: rgba(242, 242, 242, 0.6);
  width: 100vw;
  top: 0;
  height: 100vh;
  @include center-flex();
  color: $neutral-100;
  left: -115vw;
  transition: left 0.2s ease-in-out;
  will-change: left;

  @include md() {
    display: flex;
    justify-content: flex-end;
    align-items: initial;
    left: auto;
    right: -115vw;
    transition: right 0.2s ease-in-out;
    will-change: right;
  }

  a {
    color: $neutral-100;
    text-decoration: none;
  }

  &__inner {
    position: relative;
    margin: 0 16px;
    background-color: white;
    @include size(fit-content, fit-content);
    padding: 32px 32px 64px 32px;
    box-shadow: 0px 12px 32px 8px rgba(0, 0, 0, 0.2);
    border: solid 2px $neutral-100;
    min-width: 604px;

    @include md() {
      padding: 8px;
      min-width: auto;
      border-radius: 15px;
      border-width: 1px;
      top: 0;
      margin: 0;
      width: 264px;
      height: 116px;
    }

    svg {
      cursor: pointer;
    }
  }

  &__top-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 48px;
    position: relative;
    z-index: 1;

    @include md() {
      height: 20px;
      padding-bottom: 0;
      svg {
        @include size(13.5px, 13.5px)
      }
    }
  }

  &__title {
    text-transform: uppercase;
    @include font(20, 24px, 400);

    @include md() {
      @include font(12, 19.5px, 700);
      text-transform: none;
    }
  }

  &__decoration {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }

  &_show {
    left: 0;
    @include md() {
      right: 0;
      left: 18px;
    }
  }
}

.share-dialog {
  @include md() {
    left: auto;
    top: 30px;
    position: absolute;
    background-color: transparent;
  }

  &__socials {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 1;

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @include size(117px, 88px);
      border: solid 1.5px $neutral-100;

      @include xs() {
        @include size(94px, 70px);
      }

      span {
        @include font(14, 24px, 400);
        display: inline-block;
        padding-top: 8px;
      }
    }
  }

  &__socials-mob {
    display: none;
    @include md() {
      display: flex;
      align-items: center;
      margin: 8px 0;
      a {
        @include size(25px, 25px);
        border: solid 1px $neutral-100;
        border-radius: 6px;
        @include center-flex();
        margin-right: 8px;
      }
    }
  }
  
  &__url {
    margin-top: 32px;

    @include md() {
      margin-top: 0;
    }

    &-title {
      @include font(14, 24px, 400);
      display: inline-block;
      margin-bottom: 8px;
    }

    &-input {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      height: 42px;
      border-radius: 74px;
      padding: 0 12px;
      box-sizing: border-box;
      border: solid 1.5px $neutral-100;

      @include md() {
        height: 34px;
      }

      p {
        margin-right: 10px;
        @include font(14, 24px, 400);
        max-width: 220px;
        @include line-clamp(1);

        @include xs() {
          @include font(12, 14px, 400);
          max-width: 190px
        }
      }

      span {
        @include center-flex();
        cursor: pointer;
        @include font(16, 24px, 700);

        @include xs() {
          @include font(13, 24px, 700);
        }

        svg {
          margin-left: 8px;
        }
      }
    }
  }
}

.typo-dialog {
  .dialog__inner {
    padding-bottom: 32px;
    min-height: 430px;
  }

  &__selected-text {
    max-width: 540px;
    @include font(16, 24px, 400);
    font-family: 'Tzimmes';
    padding-bottom: 48px;

    span {
      background-color: #D8FA6F;
    }

    &::before {
      content: "“...";
    }

    &::after {
      content: "...”";
    }
  }

  button {
    @include font(16, 24px, 700);
    color: $neutral-100;
    background-color: transparent;
    width: fit-content;
    margin: 0 auto;
    cursor: pointer;
    margin-top: 48px;
  }

  &__form {
    display: flex;
    flex-direction: column;

    textarea {
      max-width: 540px;
      height: 94px;
      padding: 10px 12px;
      box-sizing: border-box;
      outline: none;
      border: solid 2px $neutral-100;
      border-radius: 12px;
      font-family: 'HeliosExt';
      @include font(14, 24px, 400);
      color: $neutral-100;
      background-color: transparent;
      resize: none;

      &::placeholder {
        @include font(14, 24px, 400);
        color: $neutral-70;
      }
    }

    label {
      @include font(14, 24px, 400);
      margin-bottom: 8px;
      color: $neutral-100;
      display: inline-block;
    }
  }

  &__success-block {
    display: flex;
    margin-top: 9%;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__success-title {
    text-transform: uppercase;
    margin-bottom: 24px;
    @include font(20, 24px, 400);
  }

  &__success-text {
    @include font(16, 19.2px, 400);
  }
}