.header {
  width: 100%;
  background-color: $white;
  position: sticky;
  top: 0;
  z-index: 100;

  &__sepicalTopics {
    border-bottom: solid 1px $neutral-100;
    border-top: solid 1px $neutral-100;
    height: 40px;
    @include center-flex();
    background-color: $white;

    ul {
      width: 100%;
      @include center-flex();

      @include md {
        padding: 0 16px;
        overflow-x: scroll;
        justify-content: flex-start;
        @include hide-scroll();
      }
    }

    &_item {
      padding: 0 32px;
      border-right: dashed 1px black;

      @include sm {
        padding: 0 16px;
      }

      &:last-of-type {
        padding-right: 0;
        border-right: none;
      }

      &:first-of-type {
        padding-left: 0;
      }

      a {
        @include font(18, 21.6px, 700);
        color: $neutral-100;
        transition: color 0.2s ease;
        white-space: nowrap;

        &:hover {
          color: $primary-orange;
        }
      }

      &_active {
        a {
          color: $primary-orange;
        }
      }
    }

  }

  &__inner {
    display: flex;
    align-items: center;
    height: 90px;
    justify-content: space-between;
    transition: all 0.3s ease-in-out;

    @include md() {
      height: 63px;
    }
  }

  >div.container {
    width: calc(100% - 60px);
    padding-left: 0;
    padding-right: 0;
    max-width: 1312px;

    @include md() {
      width: calc(100% - 32px);
      padding-bottom: 0;
    }
  }

  &_no-border {
    .header__sepicalTopics {
      border-bottom: none;
    }
  }

  &_scrolled {
    box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px;
  }

  &__category-name {
    @include font(48, 57px, 900);
    letter-spacing: 0.045em;
    position: relative;
    color: transparent;
    user-select: none;

    -webkit-text-stroke: 1px $neutral-100;
    text-stroke: 1px $neutral-100;
    text-transform: uppercase;
    padding-top: 11px;
    text-align: center;

    @include sm() {
      display: none;
    }

    svg {
      position: absolute;
      z-index: 100;
      right: -13px;
      z-index: -1;
      top: -5px;
    }
  }

  &__nav {

    >ul {
      display: flex;
      align-items: center;
    }

    svg {
      position: absolute;
      transition: opacity 0.2s ease;
      top: -7px;
      left: -8px;
      width: calc(100% + 14px);
      height: calc(100% + 7px);
      z-index: -1;
      opacity: 0;
    }

    a {
      color: $neutral-100;
      @include font(20, 24px, 400);
      text-transform: uppercase;
      // letter-spacing: 0.6px;
      border-radius: 50%;
      display: inline-block;
      transition: text-shadow 0.2s ease;

      position: relative;
      padding: 7px 14px;

      &:hover {
        text-shadow: 0 0 .01px $neutral-100, 0 0 .01px $neutral-100, 0 0 .01px $neutral-100;
        // font-weight: 700;

        // letter-spacing: 0px;
        >svg {
          opacity: 1;
        }
      }
    }

    a.header__nav_active {
      svg {
        opacity: 1;
      }

      font-weight: 700;

      &:hover {
        text-shadow: none;
      }
    }

    li {
      margin-right: 8px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &__logo {
    @include size(152px, 84px);

    svg {
      @include size(inherit, inherit);
    }

    @include md {
      @include size(100px, 47px);
    }
  }

  &__burger-btn {
    cursor: pointer;
    width: 152px;
    display: flex;
    justify-content: flex-end;

    @include md {
      svg {
        @include size(40px, 22px);
      }
    }
  }

  div.header_mob {
    display: none;

    @include md() {
      border: none;
      display: flex;
      padding-left: 16px;
      height: fit-content;

      .header__nav {
        padding-bottom: 8px;
        white-space: nowrap;
        overflow-x: scroll;
        @include hide-scroll();

        li {
          padding: 0;

          a.header__nav_active {
            font-weight: 700;
          }

          a {
            padding: 0;
            margin-right: 20px;
            @include font(18, 22.25px, 400);
          }
        }
      }
    }
  }
}

.header_gap {
  padding-bottom: 110px;
}