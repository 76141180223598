.burger-menu {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: $white;
  z-index: 1000;
  overflow-x: hidden;
  bottom: -102vh;
  left: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  // overflow-y: scroll;
  @include hide-scroll();
  background-color: $white;
  display: flex;
  flex-direction: column;
  padding-top: 10px;

  @include md() {
    padding-top: 24px;
  }

  >div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }


  &_opened {
    bottom: -130px;
    visibility: visible;
    @include md() {
      bottom: -100px;
    }
  }

  &__nav-list {
    display: flex;
    flex-direction: column;
    padding-top: 32px;

    @include sm() {
      padding-top: 24px;
    }

    a {
      transition: color 0.2s ease-in-out;
    }

    &-item {
      margin-top: 32px;
      display: flex;

      @include md() {
        margin-top: 24px;
        flex-direction: column;
      }

      >a {
        text-transform: uppercase;
        @include font(48, 57px, 900);
        color: transparent;
        -webkit-text-stroke: 1px $neutral-100;
        text-stroke: 1px $neutral-100;
        position: relative;
        width: fit-content;

        @include md() {
          @include font(36, 43px);
        }

        &::before {
          content: '';
          position: absolute;
          width: 100%;
          z-index: -1;
          height: 100%;
          background: $primary;
          filter: blur(17.5px);
          opacity: 0;
          transition: opacity 0.2s ease-in-out;
        }

        &:hover {
          color: $neutral-100;

          &::before {
            opacity: 1;
          }
        }
      }

      &:hover {
        >ul.burger-menu__nav-list-nested {
          max-width: 500px;

          @include sm() {
            max-height: 500px;
          }

          opacity: 1;
          visibility: visible;
        }
      }

    }

    &-nested {
      display: flex;
      align-items: flex-end;
      margin-left: 24px;
      position: relative;
      top: -12px;
      max-width: 0px;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease-in-out;
      max-width: 0px;

      @include md() {
        max-height: 0px;
        top: 0;
        margin-left: 0;
        flex-direction: column;
        align-items: flex-start;
      }

      li {
        padding-top: 16px;

        >span {
          margin: 0 16px;
          @include font(20, 24px 400);
          color: $neutral-100;
        }

        @include md() {
          padding-top: 10px;

          span {
            display: none;
          }
        }

        &:last-child {
          span {
            display: none
          }
        }

        a {
          @include font(20, 24px, 400);
          color: $neutral-100;
          transition: all 0.2s ease;

          @include md() {
            @include font(18, 22px, 700);
          }

          &:hover {
            text-shadow: 0 0 .01px $neutral-100, 0 0 .01px $neutral-100, 0 0 .01px $neutral-100;
          }
        }
      }
    }

  }

  &__bottom-list {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: auto;
    padding-bottom: calc(132px + 36px);
    justify-content: space-between;
    padding-top: 94px;

    @include md() {
      padding-bottom: 132px;
    }

    @include sm() {
      flex-direction: column;
      padding-top: 94px;
    }
  }

  &__additional-nav {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    top: 3px;

    @include md() {
      flex-wrap: wrap;
    }

    @include sm() {
      top: 0;
    }

    li {
      margin-right: 32px;

      @include sm() {
        margin-top: 10px;
      }
    }

    a {
      color: $neutral-100;
      @include font(20, 24px, 400);

      @include sm() {
        @include font(18, 22px);
      }
    }
  }

  &__socials {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    @include sm() {
      justify-content: flex-start;
      margin-top: 32px;
    }

    a {
      @include size(38px, 38px);
      @include center-flex();
      border-radius: 10px;
      border: solid 1px $neutral-100;
      margin-right: 24px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

}