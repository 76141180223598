// ---------------------------------------------- HeliosExt Font Start ----------------------------------------------

@font-face {
  font-family: "HeliosExt";
  src: url("../fonts/HeliosExt/HeliosExtLight.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "HeliosExt";
  src: url("../fonts/HeliosExt/HeliosExt.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

// @font-face {
//   font-family: "HeliosExt";
//   src: url("../fonts/HeliosExt/GothamPro-Medium.ttf") format("truetype");
//   font-weight: 500;
//   font-style: normal;
// }

// @font-face {
//   font-family: "HeliosExt";
//   src: url("../fonts/HeliosExt/HeliosExt-DemiBold.ttf") format("truetype");
//   font-weight: 600;
//   font-style: normal;
// }

@font-face {
  font-family: "HeliosExt";
  src: url("../fonts/HeliosExt/HeliosExt-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "HeliosExt";
  src: url("../fonts/HeliosExt/HeliosExtBlack.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

// ---------------------------------------------- HeliosExt Font End ------------------------------------------------


// ---------------------------------------------- Tzimmes Font Start ----------------------------------------------

@font-face {
  font-family: "Tzimmes";
  src: url("../fonts/Tzimmes/Tzimmes-Light.otf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Tzimmes";
  src: url("../fonts/Tzimmes/Tzimmes-Regular.otf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Tzimmes";
  src: url("../fonts/Tzimmes/Tzimmes-Medium.otf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Tzimmes";
  src: url("../fonts/Tzimmes/Tzimmes-SemiBold.otf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Tzimmes";
  src: url("../fonts/Tzimmes/Tzimmes-Bold.otf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Tzimmes";
  src: url("../fonts/Tzimmes/Tzimmes-ExtraBold.otf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

// ---------------------------------------------- Tzimmes Font End ------------------------------------------------

// ---------------------------------------------- SFProText Font Start ----------------------------------------------

@font-face {
  font-family: "SFProText";
  src: url("../fonts/SFProText/SFProText-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

// ---------------------------------------------- SFProText Font End ------------------------------------------------